import React, { useEffect } from 'react';

export default function MainToSPDF() {
    useEffect(() => {
        const link = document.createElement('a');
        link.href = 'https://www.blinkplanet.com/terms.pdf';
        link.download = 'tos.pdf';
        
        link.click();
    }, []);

    return <div></div>;
}